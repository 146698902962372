// Package Dependencies
// Additional Dependencies
import {
  ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
  ADD_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE,
  ADD_STUDENT_ID_VERIFICATION_INQUIRY_ERROR,
} from "../../action/Student/AddStudentIDVerificationInquiry";

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  error: false,
  response: false,
  data: {},
};

// Add Student ID Verification Inquiry Reducer
function AddStudentIDVerificationInquiryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Add Student ID Verification Inquiry Request
    case ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false,
      };
    // Add Student ID Verification Inquiry Response
    case ADD_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.data,
      };
    // Add Student ID Verification Inquiry Error
    case ADD_STUDENT_ID_VERIFICATION_INQUIRY_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: "Add Student ID Verification Inquiry Inquiry Error",
      };
    default:
      return state;
  }
}

// Export
export default AddStudentIDVerificationInquiryReducer;
