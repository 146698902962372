// Package Dependencies
import { all } from 'redux-saga/effects';
import { RetrieveStudentIDVerificationInquirySaga } from './Student/RetrieveStudentIDVerificationInquiry';
import { AddStudentIDVerificationInquirySaga } from './Student/AddStudentIDVerificationInquiry';


// Export
export default function* RootSaga() {
  yield all([
    ...RetrieveStudentIDVerificationInquirySaga,
    ...AddStudentIDVerificationInquirySaga
  ]);
}
