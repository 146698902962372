// Add Student ID Verification Inquiry Request
export const ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST =
  "ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST";
export function addStudentIDVerificationInquiryRequest(data) {
  return { type: ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST, data };
}

// Add Student ID Verification Inquiry Error
export const ADD_STUDENT_ID_VERIFICATION_INQUIRY_ERROR =
  "ADD_STUDENT_ID_VERIFICATION_INQUIRY_ERROR";
export function addStudentIDVerificationInquiryError(data) {
  return { type: ADD_STUDENT_ID_VERIFICATION_INQUIRY_ERROR, data };
}

// Add Student ID Verification Inquiry Response
export const ADD_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE =
  "ADD_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE";
export function addStudentIDVerificationInquiryResponse(data) {
  return { type: ADD_STUDENT_ID_VERIFICATION_INQUIRY_RESPONSE, data };
}
