// Package Dependencies
import { combineReducers } from 'redux';

// Additional Dependencies
import RetrieveStudentIDVerificationInquiryReducer from './Student/RetrieveStudentIDVerificationInquiry';
import AddStudentIDVerificationInquiryReducer from './Student/AddStudentIDVerificationInquiry';

// Combine Reducers
const RootReducer = combineReducers({
  retrieveStudentIDVerificationInquiryReducer: RetrieveStudentIDVerificationInquiryReducer,
  addStudentIDVerificationInquiryReducer: AddStudentIDVerificationInquiryReducer
});

// Export
export default RootReducer;
