// Package Dependencies
import { call, put, takeLatest } from "redux-saga/effects";

// Action Dependencies
import {
  ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
  addStudentIDVerificationInquiryError,
  addStudentIDVerificationInquiryResponse,
} from "../../action/Student/AddStudentIDVerificationInquiry";

// Additional Dependencies
import Axios from "../../utility/Axios";

// Function Handle Create Student ID Verification Inquiry
function* handleAddStudentIDVerificationInquiry(action) {
  try {
    // Define Resource
    const resource = `/student/idverification/add`;
    const data = {
      tenant_id: action.data.tenant_id,
      company_id: action.data.company_id,
      student_id: action.data.student_id,

      idVerificationRecord: {
        vendor_id: "1", // vendor_id is hardcoded to 1 for now
        verification_status: "pending", // inquiry is created with pending status in personal
        inquiry_id: action.data.inquiry_id,
        verification_data: {},
        inquriy_data: {},
      },
    };
    // Create Student ID Verification Inquiry Request
    const response = yield call(Axios.post_request, resource, data);
    // Create Student ID Verification Inquiry Response
    yield put(addStudentIDVerificationInquiryResponse(response.data));
  } catch (error) {
    // Create Student ID Verification Inquiry Error
    yield put(addStudentIDVerificationInquiryError(error));
  }
}

// Export
export const AddStudentIDVerificationInquirySaga = [
  takeLatest(
    ADD_STUDENT_ID_VERIFICATION_INQUIRY_REQUEST,
    handleAddStudentIDVerificationInquiry
  ),
];
